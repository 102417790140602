body {
  font-family: sans-serif;
  padding: 1em;
  background: #4c5a77;
}

.ais-ClearRefinements {
  margin: 1em 0;
}

.container {
  max-width: 1400px; /* Limite la largeur maximale */
  margin: auto; /* Centrage */
  padding: 0 1em; /* Espace intérieur pour les petites résolutions */
}

.h1 {
  color: white !important;
}

.ais-SearchBox {
  margin: 1em 0;
  border: 0;
  background: 0 0;
  padding: 0;
  outline: none;
  position: relative;
  top: 0;
  color: #88898b;
  font-family: helveticaneue, Arial, sans-serif;
  font-size: 12px;
}

.ais-Pagination {
  margin-top: 1em;
}

.ais-InstantSearch {
  background-color: #7486ae;
}

.hit-img {
  margin-right: 1em;
  width: 100%;
  height: auto; /* Assurez-vous que l'image conserve son ratio */
  margin-bottom: 0.5em;
  max-height: 300px;
  object-fit: cover;
  margin-top:-2px;
}

article {
  padding-bottom: 0.4em;
}

.ais-Stats {
  text-align: left;
  margin-top :-0.4em;
  margin-bottom :-0.4em;
}

.ais-Stats-text {
  color: white;
  font-size: 0.7em;
}

.ais-InfiniteHits-item,
.ais-InfiniteResults-item,
.ais-Hits-item,
.ais-Results-item {
  margin-top: 1rem;
  margin-right: 1rem;
  padding: 0 !important;
  background-color: white;
  border: none !important;
  box-shadow: none !important;  
  border-radius: 6px 6px 6px 6px;
  width: calc(25% - 2em);
}

.hit-name {
  padding: 0.4em;
  font-size: 1em;
  color: white;
  font-weight: 600;

}

hr.hit-hr {
  border-top: 1px solid #fbfcff;

}
.hit-icon {
  margin-right: 0.3em;
}

/*
.ais-ToggleRefinement-label::after {
  content: url('/public/croix-rose.png'); 
  margin-right: 5px; 
}
*/

.hit-description {
  color: #1e2024;
  font-size: 0.8em; /* Taille de police ajustée */
  margin-left:0.7em;
  margin-bottom: 0.3em;
  color: #373b41;
  font-weight: 600;
  line-height: 1;
}

.hit-info {
  color: #1e2024;
  font-size: 0.8em;
  margin-left: 0.7em;
}

/* Ajout de styles pour les différentes catégories */
.image, .video, .jeu, .lien, .loop, .son, .thread, .animation {
  padding: 0.5em;
  border-radius: 4px;
}

.left-panel {
  float: left;
  width: 25%; /* Ajusté pour desktop */
}

.inner-left-panel {
  background-color: white;
  padding: 1em;
  border-radius: 4px !important;
}

.right-panel {
  float: right;
  width: 73%; /* Ajusté pour desktop */
}

.image {
  background-color: rgb(176, 185, 203);
}

.video {
  background-color: rgb(113, 197, 161);
}

.jeu {
  background-color: rgb(248, 146, 54);
}

.lien {
  background-color: rgb(91, 188, 212);
}

.loop {
  background-color: rgb(243, 93, 52);
}

.son {
  background-color: rgb(244, 192, 0);
}

.thread {
  background-color: rgb(212, 156, 91);
}

.animation {
  background-color: rgb(176, 185, 203);
}

.logo span {
  display: block;
  color: #fff;
  font-weight: 700;
  text-align: left;
  text-decoration: none;
  font-size: 20px;
  line-height: 1;
  text-transform: uppercase;
}

.logo {
  display: flex;
  align-items: center;
  margin-top: 0.7em;
  margin-bottom: 0.0em;
}

.logo img {
  margin-right: 10px; /* Ajustez la marge selon vos besoins */
}

.ais-RefinementList-item {
  padding: 0.1em;
  color: #3a4570;
  text-transform: capitalize;
}

.ais-ToggleRefinement-labelText {
  padding: 0.1em;
  color: #3a4570;
}

input[type="checkbox"][value="image"] {
  background-color: rgb(176, 185, 203);
  font-weight: 600;
}

input[type="checkbox"][value="video"] {
  background-color: rgb(113, 197, 161);
  font-weight: 600;
}

input[type="checkbox"][value="jeu"] {
  background-color: rgb(248, 146, 54);
  font-weight: 600;
}

input[type="checkbox"][value="lien"] {
  background-color: rgb(91, 188, 212);
  font-weight: 600;
}

input[type="checkbox"][value="loop"] {
  background-color: rgb(243, 93, 52);
  font-weight: 600;
}

input[type="checkbox"][value="son"] {
  background-color: rgb(244, 192, 0);
  font-weight: 600;
}

input[type="checkbox"][value="thread"] {
  background-color: rgb(212, 156, 91);
  font-weight: 600;
}

input[type="checkbox"][value="animation"] {
  background-color: rgb(176, 185, 203);
  font-weight: 600;
}


.ais-RefinementList-count {
  margin-left: 0.7em;
}

.ais-RefinementList-checkbox {
  margin-right: 0.5em;
}

/* Responsive design */

@media screen and (min-width: 820px) and (max-width: 1180px) {
  .left-panel {
    width: 25%; /* Maintain the width of the left panel */
    float: left;
  }

  .right-panel {
    width: calc(75% - 1em); /* Adjust the width of the right panel */
    float: left; /* Align to the left */
    padding-left: 1em; /* Keep the space between the panels */
    margin-left: 0; /* Ensure there is no additional margin on the left */
  }

  .ais-InfiniteHits-item, .ais-InfiniteResults-item, .ais-Hits-item, .ais-Results-item {
    width: calc(50% - 2em); /* Adjust the items to fit within the new right panel width */
  }
}


@media screen and (max-width: 768px) {
  .left-panel, .right-panel {
    width: 100%; /* Pleine largeur pour les tablettes et moins */
    float: none;
  }

  .container, .inner-left-panel {
    padding: 0.5em;
  }

  .ais-InfiniteHits-item, .ais-InfiniteResults-item, .ais-Hits-item, .ais-Results-item {
    width: calc(50% - 1rem); /* Ajustement pour une grille 2 colonnes */
  }

  .hit-description {
    padding: 0.5em; /* Espace intérieur réduit pour les descriptions */
  }
}

/* Suite du responsive design pour les écrans plus petits */
@media screen and (max-width: 480px) {
  .ais-InfiniteHits-item, .ais-InfiniteResults-item, .ais-Hits-item, .ais-Results-item {
    width: 100%; /* Pleine largeur pour les petits écrans */
    margin: 0.5rem 0; /* Ajustement de la marge */
    
  }

  .hit-img {
    max-height: 200px; /* Hauteur maximale ajustée pour les petits écrans */
    object-fit: cover;
  }

  .hit-description {
    padding: 0.3em; /* Espace intérieur réduit davantage pour les descriptions */
    font-size: 0.8em; /* Taille de police réduite pour les petits écrans */
  }

  .left-panel, .right-panel {
    padding: 0; /* Suppression de l'espacement pour les panneaux */
  }

  .inner-left-panel {
    padding: 0.5em; /* Espace intérieur ajusté pour le panneau de gauche */
  }

  .logo span {
    font-size: 18px; /* Taille de police réduite pour le logo */
  }
}

/* Styles supplémentaires pour améliorer la lisibilité et l'accessibilité */
h1, h2, h3, h4, h5, h6 {
  color: #fff; /* Couleur de police pour les titres */
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}





.ais-InfiniteHits-list, .ais-InfiniteResults-list, .ais-Hits-list, .ais-Results-list {
  margin-top: -1rem;
  margin-left: 0 !important;
  display: flex;
  flex-wrap: wrap;
}


.ais-Breadcrumb-list,
.ais-CurrentRefinements-list,
.ais-HierarchicalMenu-list,
.ais-Hits-list,
.ais-Results-list,
.ais-InfiniteHits-list,
.ais-InfiniteResults-list,
.ais-Menu-list,
.ais-NumericMenu-list,
.ais-Pagination-list,
.ais-RatingMenu-list,
.ais-RefinementList-list,
.ais-ToggleRefinement-list {
  margin: 0;
  padding: 0;
  list-style: none; }

.ais-ClearRefinements-button,
.ais-CurrentRefinements-delete,
.ais-CurrentRefinements-reset,
.ais-GeoSearch-redo,
.ais-GeoSearch-reset,
.ais-HierarchicalMenu-showMore,
.ais-InfiniteHits-loadPrevious,
.ais-InfiniteHits-loadMore,
.ais-InfiniteResults-loadMore,
.ais-Menu-showMore,
.ais-RangeInput-submit,
.ais-RefinementList-showMore,
.ais-SearchBox-submit,
.ais-SearchBox-reset,
.ais-VoiceSearch-button {
  padding: 0;
  overflow: visible;
  font: inherit;
  line-height: normal;
  color: inherit;
  background: none;
  border: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .ais-ClearRefinements-button::-moz-focus-inner,
  .ais-CurrentRefinements-delete::-moz-focus-inner,
  .ais-CurrentRefinements-reset::-moz-focus-inner,
  .ais-GeoSearch-redo::-moz-focus-inner,
  .ais-GeoSearch-reset::-moz-focus-inner,
  .ais-HierarchicalMenu-showMore::-moz-focus-inner,
  .ais-InfiniteHits-loadPrevious::-moz-focus-inner,
  .ais-InfiniteHits-loadMore::-moz-focus-inner,
  .ais-InfiniteResults-loadMore::-moz-focus-inner,
  .ais-Menu-showMore::-moz-focus-inner,
  .ais-RangeInput-submit::-moz-focus-inner,
  .ais-RefinementList-showMore::-moz-focus-inner,
  .ais-SearchBox-submit::-moz-focus-inner,
  .ais-SearchBox-reset::-moz-focus-inner,
  .ais-VoiceSearch-button::-moz-focus-inner {
    padding: 0;
    border: 0; }
  .ais-ClearRefinements-button[disabled],
  .ais-CurrentRefinements-delete[disabled],
  .ais-CurrentRefinements-reset[disabled],
  .ais-GeoSearch-redo[disabled],
  .ais-GeoSearch-reset[disabled],
  .ais-HierarchicalMenu-showMore[disabled],
  .ais-InfiniteHits-loadPrevious[disabled],
  .ais-InfiniteHits-loadMore[disabled],
  .ais-InfiniteResults-loadMore[disabled],
  .ais-Menu-showMore[disabled],
  .ais-RangeInput-submit[disabled],
  .ais-RefinementList-showMore[disabled],
  .ais-SearchBox-submit[disabled],
  .ais-SearchBox-reset[disabled],
  .ais-VoiceSearch-button[disabled] {
    cursor: default; }

.ais-InfiniteHits-loadPrevious,
.ais-InfiniteHits-loadMore,
.ais-HierarchicalMenu-showMore,
.ais-Menu-showMore,
.ais-RefinementList-showMore {
  overflow-anchor: none; }

.ais-Breadcrumb-list,
.ais-Breadcrumb-item,
.ais-Pagination-list,
.ais-RangeInput-form,
.ais-RatingMenu-link,
.ais-PoweredBy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.ais-GeoSearch,
.ais-GeoSearch-map {
  height: 100%; }

.ais-HierarchicalMenu-list .ais-HierarchicalMenu-list {
  margin-left: 1em; }

.ais-PoweredBy-logo {
  display: block;
  height: 1.2em;
  width: auto; }

.ais-RatingMenu-starIcon {
  display: block;
  width: 20px;
  height: 20px; }

.ais-SearchBox-input::-ms-clear, .ais-SearchBox-input::-ms-reveal {
  display: none;
  width: 0;
  height: 0; }

.ais-SearchBox-input::-webkit-search-decoration, .ais-SearchBox-input::-webkit-search-cancel-button, .ais-SearchBox-input::-webkit-search-results-button, .ais-SearchBox-input::-webkit-search-results-decoration {
  display: none; }

.ais-RangeSlider .rheostat {
  overflow: visible;
  margin-top: 40px;
  margin-bottom: 40px; }

.ais-RangeSlider .rheostat-background {
  height: 6px;
  top: 0px;
  width: 100%; }

.ais-RangeSlider .rheostat-handle {
  margin-left: -12px;
  top: -7px; }

.ais-RangeSlider .rheostat-background {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #aaa; }

.ais-RangeSlider .rheostat-progress {
  position: absolute;
  top: 1px;
  height: 4px;
  background-color: #333; }

.rheostat-handle {
  position: relative;
  z-index: 1;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 50%;
  cursor: -webkit-grab;
  cursor: grab; }

.rheostat-marker {
  margin-left: -1px;
  position: absolute;
  width: 1px;
  height: 5px;
  background-color: #aaa; }

.rheostat-marker--large {
  height: 9px; }

.rheostat-value {
  margin-left: 50%;
  padding-top: 15px;
  position: absolute;
  text-align: center;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }

.rheostat-tooltip {
  margin-left: 50%;
  position: absolute;
  top: -22px;
  text-align: center;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }

/**
 * Reset
 */
[class^='ais-'] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

/**
 * Disabled
 */
[class^='ais-'][class$='--disabled'],
[class^='ais-'][class$='--disabled'] *,
.ais-VoiceSearch-button:disabled {
  cursor: not-allowed; }

/**
 * Buttons
 */
.ais-HierarchicalMenu-showMore,
.ais-Menu-showMore,
.ais-RefinementList-showMore,
.ais-ClearRefinements-button,
.ais-InfiniteHits-loadMore,
.ais-RangeInput-submit,
.ais-InfiniteHits-loadPrevious,
.ais-Pagination-link,
.ais-GeoSearch-redo,
.ais-GeoSearch-reset,
.ais-VoiceSearch-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fcfcfd));
  background-image: linear-gradient(-180deg, #fff, #fcfcfd);
  border: 1px solid #d6d6e7;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 0 0 rgba(35, 38, 59, 0.05);
  box-shadow: 0 1px 0 0 rgba(35, 38, 59, 0.05);
  color: #23263b;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 0.875rem;
  font-weight: 400;
  height: 2rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1.25rem;
  padding: 0 1rem;
  place-items: center;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ais-HierarchicalMenu-showMore,
.ais-Menu-showMore,
.ais-RefinementList-showMore,
.ais-GeoSearch-redo,
.ais-GeoSearch-reset {
  height: 1.5rem; }

.ais-HierarchicalMenu-showMore,
.ais-Menu-showMore,
.ais-RefinementList-showMore {
  margin-top: 1rem; }

.ais-HierarchicalMenu-showMore:hover,
.ais-Menu-showMore:hover,
.ais-RefinementList-showMore:hover,
.ais-ClearRefinements-button:hover,
.ais-InfiniteHits-loadMore:hover,
.ais-RangeInput-submit:hover,
.ais-InfiniteHits-loadPrevious:hover,
.ais-Pagination-item:not(.ais-Pagination-item--selected):not(.ais-Pagination-item--disabled)
.ais-Pagination-link:hover,
.ais-GeoSearch-redo:hover,
.ais-GeoSearch-reset:hover,
.ais-VoiceSearch-button:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#f5f5fa));
  background-image: linear-gradient(-180deg, #fff, #f5f5fa);
  border-color: #d6d6e7; }

.ais-HierarchicalMenu-showMore:focus,
.ais-Menu-showMore:focus,
.ais-RefinementList-showMore:focus,
.ais-ClearRefinements-button:focus,
.ais-InfiniteHits-loadMore:focus,
.ais-RangeInput-submit:focus,
.ais-InfiniteHits-loadPrevious:focus,
.ais-GeoSearch-redo:focus,
.ais-GeoSearch-reset:focus,
.ais-VoiceSearch-button:focus {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#f5f5fa));
  background-image: linear-gradient(-180deg, #fff, #f5f5fa);
  border-color: #3c4fe0;
  -webkit-box-shadow: #3c4fe0 0 0 0 1px, rgba(35, 38, 59, 0.05) 0 2px 0 1px;
  box-shadow: #3c4fe0 0 0 0 1px, rgba(35, 38, 59, 0.05) 0 2px 0 1px;
  outline: currentcolor none medium; }

.ais-HierarchicalMenu-showMore:active,
.ais-Menu-showMore:active,
.ais-RefinementList-showMore:active,
.ais-ClearRefinements-button:active,
.ais-InfiniteHits-loadMore:active,
.ais-InfiniteHits-loadPrevious:active,
.ais-Pagination-item--selected .ais-Pagination-link,
.ais-Pagination-link:active,
.ais-GeoSearch-redo:active,
.ais-GeoSearch-reset:active,
.ais-VoiceSearch-button:active {
  border-color: #d6d6e7;
  -webkit-box-shadow: rgba(119, 122, 175, 0.4) 0 1px 4px 0 inset, rgba(119, 122, 175, 0.4) 0 1px 1px 0 inset, rgba(35, 38, 59, 0.05) 0 1px 0 0;
  box-shadow: rgba(119, 122, 175, 0.4) 0 1px 4px 0 inset, rgba(119, 122, 175, 0.4) 0 1px 1px 0 inset, rgba(35, 38, 59, 0.05) 0 1px 0 0; }

.ais-ClearRefinements-button:disabled[disabled],
.ais-InfiniteHits-loadMore:disabled[disabled],
.ais-InfiniteHits-loadPrevious:disabled[disabled],
.ais-Pagination-item--disabled .ais-Pagination-link,
.ais-GeoSearch-redo--disabled,
.ais-GeoSearch-reset--disabled,
.ais-VoiceSearch-button:disabled {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#f5f5fa));
  background-image: linear-gradient(-180deg, #fff, #f5f5fa);
  border-color: #efeff5;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #b6b7d5; }

.ais-HierarchicalMenu-showMore--disabled[disabled],
.ais-Menu-showMore--disabled[disabled],
.ais-RefinementList-showMore--disabled[disabled] {
  display: none; }

/**
 * Labels
 */
.ais-NumericMenu-label,
.ais-ToggleRefinement-label,
.ais-HierarchicalMenu-item,
.ais-Menu-item,
.ais-RatingMenu-item,
.ais-RefinementList-item {
  display: block;
  font-size: 0.875rem;
  line-height: 1.75rem; }

.ais-HierarchicalMenu-link,
.ais-Menu-link,
.ais-RatingMenu-link,
.ais-RefinementList-label {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.ais-Breadcrumb-link,
.ais-HierarchicalMenu-link,
.ais-Menu-link,
.ais-RatingMenu-link {
  color: inherit;
  text-decoration: none; }

.ais-Breadcrumb-link:hover {
  text-decoration: underline; }

.ais-HierarchicalMenu-label,
.ais-Menu-label,
.ais-RefinementList-labelText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.ais-HierarchicalMenu-item--selected
> .ais-HierarchicalMenu-link
> .ais-HierarchicalMenu-label,
.ais-Menu-item--selected .ais-Menu-label,
.ais-RefinementList-item--selected,
.ais-RatingMenu-item--selected {
  font-weight: 600; }

/**
 * Count
 */
.ais-ToggleRefinement-count,
.ais-HierarchicalMenu-count,
.ais-Menu-count,
.ais-RatingMenu-count,
.ais-RefinementList-count {
  background: #f5f5fa none repeat scroll 0% 0%;
  border: 1px solid #b6b7d5;
  border-radius: 99999px;
  color: #23263b;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  margin-left: 0.5rem;
  overflow: hidden;
  padding: 0 0.25rem; }

/**
 * No Results
 */
.ais-Menu-noResults,
.ais-HierarchicalMenu-noResults,
.ais-RefinementList-noResults {
  color: #5a5e9a;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 1rem; }

/**
 * Selectors
 */
.ais-MenuSelect-select,
.ais-HitsPerPage-select,
.ais-SortBy-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="24" xmlns="http://www.w3.org/2000/svg"><g stroke="%23777aaf" stroke-width="2" fill="none" fill-rule="evenodd"><path d="M13 7L7 1 1 7M1 17l6 6 6-6"/></g></svg>'), -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fcfcfd));
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="24" xmlns="http://www.w3.org/2000/svg"><g stroke="%23777aaf" stroke-width="2" fill="none" fill-rule="evenodd"><path d="M13 7L7 1 1 7M1 17l6 6 6-6"/></g></svg>'), linear-gradient(-180deg, #fff, #fcfcfd);
  background-position: right 1rem center, 0 0;
  background-repeat: no-repeat;
  background-size: 10px, auto;
  border: 1px solid #d6d6e7;
  border-radius: 3px;
  -webkit-box-shadow: rgba(35, 38, 59, 0.05) 0 1px 0 0;
  box-shadow: rgba(35, 38, 59, 0.05) 0 1px 0 0;
  color: #23263b;
  cursor: pointer;
  font: inherit;
  height: 2.5rem;
  outline: currentcolor none medium;
  padding: 0 2.5rem 0 1rem;
  position: relative;
  width: 100%; }

.ais-HitsPerPage-select:-moz-focusring,
.ais-HitsPerPage-select::-moz-focus-inner,
.ais-SortBy-select:-moz-focusring,
.ais-SortBy-select::-moz-focus-inner {
  color: transparent;
  outline: currentcolor none medium; }

.ais-HitsPerPage-select > option:not(:checked),
.ais-SortBy-select > option:not(:checked) {
  color: #23263b; }

.ais-HitsPerPage-select > option:disabled,
.ais-SortBy-select > option:disabled {
  color: #b6b7d5; }

.ais-HitsPerPage-select:hover,
.ais-SortBy-select:hover {
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="24" xmlns="http://www.w3.org/2000/svg"><g stroke="%23777aaf" stroke-width="2" fill="none" fill-rule="evenodd"><path d="M13 7L7 1 1 7M1 17l6 6 6-6"/></g></svg>'), -webkit-gradient(linear, left top, left bottom, from(#fcfcfd), to(#f5f5fa));
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="24" xmlns="http://www.w3.org/2000/svg"><g stroke="%23777aaf" stroke-width="2" fill="none" fill-rule="evenodd"><path d="M13 7L7 1 1 7M1 17l6 6 6-6"/></g></svg>'), linear-gradient(-180deg, #fcfcfd, #f5f5fa);
  border-color: #d6d6e7; }

.ais-HitsPerPage-select:focus,
.ais-SortBy-select:focus {
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="24" xmlns="http://www.w3.org/2000/svg"><g stroke="%23777aaf" stroke-width="2" fill="none" fill-rule="evenodd"><path d="M13 7L7 1 1 7M1 17l6 6 6-6"/></g></svg>'), -webkit-gradient(linear, left top, left bottom, from(#fff), to(#f5f5fa));
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="24" xmlns="http://www.w3.org/2000/svg"><g stroke="%23777aaf" stroke-width="2" fill="none" fill-rule="evenodd"><path d="M13 7L7 1 1 7M1 17l6 6 6-6"/></g></svg>'), linear-gradient(-180deg, #fff, #f5f5fa);
  border-color: #3c4fe0;
  -webkit-box-shadow: #3c4fe0 0 0 0 1px, rgba(35, 38, 59, 0.05) 0 2px 0 0;
  box-shadow: #3c4fe0 0 0 0 1px, rgba(35, 38, 59, 0.05) 0 2px 0 0; }

.ais-HitsPerPage-select:disabled,
.ais-SortBy-select:disabled {
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="24" xmlns="http://www.w3.org/2000/svg"><g stroke="%23b6b7d5" stroke-width="2" fill="none" fill-rule="evenodd"><path d="M13 7L7 1 1 7M1 17l6 6 6-6"/></g></svg>'), -webkit-gradient(linear, left top, left bottom, from(#fff), to(#f5f5fa));
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="24" xmlns="http://www.w3.org/2000/svg"><g stroke="%23b6b7d5" stroke-width="2" fill="none" fill-rule="evenodd"><path d="M13 7L7 1 1 7M1 17l6 6 6-6"/></g></svg>'), linear-gradient(-180deg, #fff, #f5f5fa);
  border-color: #efeff5;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #b6b7d5;
  cursor: not-allowed; }

/**
 * Panel
 */
.ais-Panel {
  margin-bottom: 2rem; }

.ais-Panel-header {
  color: #5a5e9a;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  margin-bottom: 1rem;
  text-transform: uppercase; }

.ais-Panel-footer {
  color: #5a5e9a;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  margin-top: 1rem; }

.ais-Panel--collapsible {
  position: relative; }

.ais-Panel--collapsible .ais-Panel-collapseButton {
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0; }

.ais-Panel--collapsed .ais-Panel-body,
.ais-Panel--collapsed .ais-Panel-footer {
  display: none; }

/**
 * SearchBox
 */
.ais-SearchBox-form {
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.875rem;
  height: 2.5rem;
  line-height: 1.25rem;
  position: relative;
  width: 100%; }

.ais-SearchBox-form::before {
  background: transparent url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%235a5e9a%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Ccircle%20cx%3D%2211%22%20cy%3D%2211%22%20r%3D%228%22%3E%3C%2Fcircle%3E%3Cline%20x1%3D%2221%22%20y1%3D%2221%22%20x2%3D%2216.65%22%20y2%3D%2216.65%22%3E%3C%2Fline%3E%3C%2Fsvg%3E") repeat scroll 0% 0%;
  content: '';
  height: 1rem;
  left: 1rem;
  margin-top: -0.5rem;
  position: absolute;
  top: 50%;
  width: 1rem; }

.ais-SearchBox-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid #d6d6e7;
  border-radius: 3px;
  -webkit-box-shadow: rgba(119, 122, 175, 0.3) 0 1px 4px 0 inset;
  box-shadow: rgba(119, 122, 175, 0.3) 0 1px 4px 0 inset;
  caret-color: #5a5e9a;
  color: #23263b;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  font: inherit;
  max-width: 100%;
  padding-left: 2.5rem; }

.ais-SearchBox-input::-webkit-input-placeholder {
  color: #5a5e9a; }

.ais-SearchBox-input:-ms-input-placeholder {
  color: #5a5e9a; }

.ais-SearchBox-input::placeholder {
  color: #5a5e9a; }

.ais-SearchBox-input:focus {
  border-color: #3c4fe0;
  -webkit-box-shadow: rgba(35, 38, 59, 0.05) 0 1px 0 0;
  box-shadow: rgba(35, 38, 59, 0.05) 0 1px 0 0;
  outline: currentcolor none medium; }

.ais-SearchBox-input:disabled {
  background: transparent -webkit-gradient(linear, left top, left bottom, from(#fff), to(#f5f5fa)) repeat scroll 0% 0%;
  background: transparent linear-gradient(-180deg, #fff, #f5f5fa) repeat scroll 0% 0%;
  border-color: #efeff5;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed; }

.ais-SearchBox-input:disabled::-webkit-input-placeholder {
  color: #b6b7d5;
  pointer-events: none; }

.ais-SearchBox-input:disabled:-ms-input-placeholder {
  color: #b6b7d5;
  pointer-events: none; }

.ais-SearchBox-input:disabled::placeholder {
  color: #b6b7d5;
  pointer-events: none; }

.ais-SearchBox-input::-webkit-search-cancel-button,
.ais-SearchBox-input::-webkit-search-decoration,
.ais-SearchBox-input::-webkit-search-results-button,
.ais-SearchBox-input::-webkit-search-results-decoration {
  -webkit-appearance: none;
  appearance: none; }

.ais-SearchBox-loadingIndicator,
.ais-SearchBox-reset {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  fill: #484c7a;
  height: 20px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  right: 1rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 20px; }

.ais-SearchBox-reset:focus {
  background: #b6b7d5;
  fill: #fff;
  outline: 0; }

.ais-RefinementList-searchBox .ais-SearchBox-loadingIndicator,
.ais-RefinementList-searchBox .ais-SearchBox-reset {
  right: 0.5rem; }

.ais-SearchBox-loadingIndicator[hidden],
.ais-SearchBox-reset[hidden] {
  display: none; }

.ais-SearchBox-submit {
  display: none; }

.ais-Menu-searchBox,
.ais-RefinementList-searchBox {
  margin-bottom: 0.5rem; }

.ais-Menu-searchBox .ais-SearchBox-form,
.ais-RefinementList-searchBox .ais-SearchBox-form {
  height: 2rem; }

.ais-Menu-searchBox .ais-SearchBox-form::before,
.ais-RefinementList-searchBox .ais-SearchBox-form::before {
  left: 0.5rem; }

.ais-Menu-searchBox .ais-SearchBox-input,
.ais-RefinementList-searchBox .ais-SearchBox-input {
  padding-left: 2rem; }

/**
 * VoiceSearch
 */
.ais-VoiceSearch-button {
  color: #5a5e9a;
  height: 48px;
  width: 48px; }

.ais-VoiceSearch-button svg {
  color: currentcolor; }

/**
 * Highlighting and Snippeting
 */
.ais-Highlight-highlighted,
.ais-Snippet-highlighted {
  background-color: #ffc168;
  font-style: normal; }


/**
 * GeoSearch
 */
.ais-GeoSearch {
  position: relative; }

.ais-GeoSearch-control {
  left: 3.75rem;
  position: absolute;
  top: 1rem; }

.ais-GeoSearch-label {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f5f5fa;
  border: 1px solid #b6b7d5;
  border-radius: 3px;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 0.875rem;
  line-height: 1.5;
  min-height: 1.5rem;
  padding: 0.25rem 0.5rem; }

.ais-GeoSearch-label,
.ais-GeoSearch-redo,
.ais-GeoSearch-reset {
  white-space: nowrap; }

.ais-GeoSearch-reset {
  bottom: 1.25rem;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }

/**
 * RefinementList
 */
.ais-RefinementList-checkbox,
.ais-GeoSearch-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-position: 50%;
  background-size: 180%;
  border: 1px solid currentcolor;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 4px 0 rgba(119, 122, 175, 0.4);
  box-shadow: inset 0 1px 4px 0 rgba(119, 122, 175, 0.4);
  color: #d6d6e7;
  cursor: inherit;
  height: 1rem;
  margin: 0 0.5rem 0 0;
  min-width: 1rem; }

.ais-RefinementList-item--selected .ais-RefinementList-checkbox,
.ais-GeoSearch-input:checked {
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%235468ff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpolyline%20points%3D%2220%206%209%2017%204%2012%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E");
  background-size: 14px;
  border-color: currentcolor;
  -webkit-box-shadow: rgba(35, 38, 59, 0.05) 0 1px 0 0 inset;
  box-shadow: rgba(35, 38, 59, 0.05) 0 1px 0 0 inset;
  color: #3c4fe0; }

.ais-RefinementList-checkbox:focus,
.ais-GeoSearch-input:focus {
  outline: currentcolor none medium; }

.ais-RefinementList-item--selected .ais-RefinementList-checkbox:focus,
.ais-GeoSearch-input:checked:focus {
  -webkit-box-shadow: rgba(35, 38, 59, 0.05) 0 1px 0 0 inset, currentcolor 0 0 0 1px;
  box-shadow: rgba(35, 38, 59, 0.05) 0 1px 0 0 inset, currentcolor 0 0 0 1px; }

/**
 * NumericMenu
 */
.ais-NumericMenu-label {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.ais-NumericMenu-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff;
  border: 1px solid #d6d6e7;
  border-radius: 50%;
  -webkit-box-shadow: inset 0 1px 4px 0 rgba(119, 122, 175, 0.3);
  box-shadow: inset 0 1px 4px 0 rgba(119, 122, 175, 0.3);
  height: 16px;
  margin: 0 0.5rem 0 0;
  outline: 0;
  position: relative;
  width: 16px; }

.ais-NumericMenu-radio:checked,
.ais-NumericMenu-radio:focus {
  border-color: #3c4fe0;
  -webkit-box-shadow: 0 1px 0 0 rgba(35, 38, 59, 0.05);
  box-shadow: 0 1px 0 0 rgba(35, 38, 59, 0.05); }

.ais-NumericMenu-radio:focus {
  -webkit-box-shadow: 0 0 0 1px #3c4fe0, 0 1px 0 0 rgba(35, 38, 59, 0.05);
  box-shadow: 0 0 0 1px #3c4fe0, 0 1px 0 0 rgba(35, 38, 59, 0.05); }

.ais-NumericMenu-radio:checked:after {
  background: #3c4fe0;
  border-radius: 50%;
  bottom: 4px;
  content: '';
  left: 4px;
  position: absolute;
  right: 4px;
  top: 4px; }

/**
 * HierarchicalMenu
 */
.ais-HierarchicalMenu-list .ais-HierarchicalMenu-list {
  margin-left: 1.5rem; }

.ais-HierarchicalMenu-link::before {
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%23b6b7d5%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpolyline%20points%3D%2218%2015%2012%209%206%2015%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  content: '';
  display: inline-block;
  height: 1rem;
  margin-right: 0.5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1rem; }

.ais-HierarchicalMenu-item--selected > .ais-HierarchicalMenu-link::before {
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%235468ff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpolyline%20points%3D%226%209%2012%2015%2018%209%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E"); }

/**
 * RatingMenu
 */
.ais-RatingMenu-starIcon {
  margin-right: 0.25rem; }

.ais-RatingMenu-starIcon {
  fill: #5a5e9a;
  position: relative;
  width: 16px; }

.ais-RatingMenu-item--disabled .ais-RatingMenu-starIcon {
  fill: #b6b7d5; }

.ais-RatingMenu-item--disabled .ais-RatingMenu-count,
.ais-RatingMenu-item--disabled .ais-RatingMenu-label {
  color: #b6b7d5; }

/**
 * ClearRefinements
 */
.ais-ClearRefinements-button {
  width: 100%; }

/**
 * CurrentRefinements
 */
.ais-CurrentRefinements-list {
  display: inline-grid;
  gap: 1rem;
  grid-auto-flow: column; }

.ais-CurrentRefinements-item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f5f5fa;
  border: 1px solid #b6b7d5;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 0.875rem;
  line-height: 1.5;
  min-height: 1.5rem;
  padding: 0.05rem 0.5rem; }

.ais-CurrentRefinements-category {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 0.5rem; }

.ais-CurrentRefinements-delete {
  color: #9698c3;
  height: 100%;
  margin-left: 0.25rem;
  outline-width: 0; }

/**
 * ToggleRefinement
 */
.ais-ToggleRefinement-label {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.ais-ToggleRefinement-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: rgba(65, 66, 71, 0.08);
  background: #f5f5fa;
  border-radius: 9999px;
  -webkit-box-shadow: inset 0 1px 4px 0 rgba(119, 122, 175, 0.4);
  box-shadow: inset 0 1px 4px 0 rgba(119, 122, 175, 0.4);
  height: 18px;
  pointer-events: none;
  position: relative;
  width: 32px; }

.ais-ToggleRefinement-checkbox:checked {
  background: #3c4fe0;
  -webkit-box-shadow: inset 0 1px 4px 0 rgba(35, 38, 59, 0.1);
  box-shadow: inset 0 1px 4px 0 rgba(35, 38, 59, 0.1); }

.ais-ToggleRefinement-checkbox:focus {
  outline: 0; }

.ais-ToggleRefinement-checkbox::after {
  background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#f5f5fa));
  background: linear-gradient(-180deg, #fff, #f5f5fa);
  border-radius: 9999px;
  -webkit-box-shadow: 1px 1px 1px 0 rgba(35, 38, 59, 0.05);
  box-shadow: 1px 1px 1px 0 rgba(35, 38, 59, 0.05);
  content: '';
  height: 16px;
  left: 1px;
  position: absolute;
  top: 1px;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
  width: 16px; }

.ais-ToggleRefinement-checkbox:checked::after {
  -webkit-transform: translateX(14px);
  transform: translateX(14px); }

/**
 * RangeInput
 */
.ais-RangeInput-input {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #d6d6e7;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 4px 0 rgba(119, 122, 175, 0.3);
  box-shadow: inset 0 1px 4px 0 rgba(119, 122, 175, 0.3);
  caret-color: #5a5e9a;
  color: #23263b;
  cursor: text;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 0.875rem;
  height: 2rem;
  line-height: 1.25rem;
  min-width: 0;
  overflow: hidden;
  padding: 0 1rem;
  position: relative; }

.ais-RangeInput-input:focus {
  border-color: #3c4fe0;
  -webkit-box-shadow: 0 1px 0 0 rgba(35, 38, 59, 0.05);
  box-shadow: 0 1px 0 0 rgba(35, 38, 59, 0.05);
  outline: 0; }

.ais-RangeInput-separator {
  margin: 0 0.5rem; }

.ais-RangeInput-submit {
  margin-left: 0.5rem; }

/**
 * RangeSlider
 */
.ais-RangeSlider .rheostat {
  margin: 40px 6px; }

.ais-RangeSlider .rheostat-horizontal {
  cursor: pointer;
  width: calc(100% - 15px); }

.ais-RangeSlider .rheostat-background {
  background-color: transparent;
  border: none;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 0.5px 0 0 rgba(255, 255, 255, 0.05);
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 0.5px 0 0 rgba(255, 255, 255, 0.05);
  height: 4px; }

.ais-RangeSlider .rheostat-progress {
  background-color: #3c4fe0;
  border-radius: 3px;
  height: 4px;
  max-width: 100%;
  top: 0; }

.ais-RangeSlider .rheostat-tooltip {
  font-weight: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ais-RangeSlider .rheostat-handle {
  background-color: #fff;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fcfcfd));
  background-image: linear-gradient(-180deg, #fff, #fcfcfd);
  border: 1px solid #d6d6e7;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 0 0 rgba(35, 38, 59, 0.05);
  box-shadow: 0 1px 0 0 rgba(35, 38, 59, 0.05);
  height: 24px;
  margin-left: -6px;
  top: -11px;
  width: 12px; }

.ais-RangeSlider .rheostat-handle::before,
.ais-RangeSlider .rheostat-handle::after {
  background-color: #d6d6e7;
  content: '';
  height: 12px;
  position: absolute;
  top: 6px;
  width: 1px; }

.ais-RangeSlider .rheostat-handle::before {
  left: 4px; }

.ais-RangeSlider .rheostat-handle::after {
  right: 4px; }

.ais-RangeSlider .rheostat-marker {
  background-color: #d6d6e7; }

/**
 * Pagination
 */
.ais-Pagination-item:first-child > .ais-Pagination-link {
  border-radius: 3px 0 0 3px; }

.ais-Pagination-item + .ais-Pagination-item > .ais-Pagination-link {
  border-radius: 0;
  margin-left: -1px; }

.ais-Pagination-item:last-child > .ais-Pagination-link {
  border-radius: 0 3px 3px 0; }

.ais-Pagination-item {
  display: inline-block; }

.ais-Pagination-item--selected .ais-Pagination-link {
  font-weight: bold; }

/**
 * Breadrumb
 */
.ais-Breadcrumb-link,
.ais-Breadcrumb-separator {
  color: #5a5e9a; }

.ais-Breadcrumb-separator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0.5rem; }

.ais-Breadcrumb-item--selected {
  font-weight: 600; }

.ais-Breadcrumb-item--selected .ais-Breadcrumb-separator {
  font-weight: normal; }

@media (max-width: 767px) {
  .ais-SearchBox-input,
  .ais-RangeInput-input {
    font-size: 1rem; } }
